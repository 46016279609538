import { useEffect,useMemo } from "react";
interface Props {
  opportunity: any;
  phase?: string;
  isBusiness?: boolean;
  activeType?:any;
}
export default function DynamicContent({ opportunity, phase, isBusiness, activeType }: Props) {
  useEffect(() => {
    let marqueeId;
    if (activeType === "draft") {
      marqueeId = `marquee-${opportunity.id}`;
    } else {
      marqueeId = `marquee-${opportunity.opportunity_id}`;
    }
    const marquee = document.getElementById(marqueeId);
    const items = marquee ? marquee.querySelectorAll("p") : [];
    let indexValue = 0;
    function showItem() {
      items.forEach((item, i) => {
        item.style.display = i === indexValue ? 'block' : 'none';
      });
      indexValue = (indexValue + 1) % items.length;
    }
    if (items.length > 0) {
      showItem();
    }
    if (items.length > 1) {
      const intervalId = setInterval(showItem, 3000);
      return () => clearInterval(intervalId);
    }
  }, [opportunity.opportunity_id,opportunity.id]);
  const views = useMemo(() => {
    if (opportunity.views < 10) {
      return "Exclusive new opportunity";
    } else if (opportunity.views >= 10 && opportunity.views <= 500) {
      return opportunity.views;
    } else if (opportunity.views > 500 && opportunity.views < 1000) {
      return "500+";
    } else if (opportunity.views >= 1000 && opportunity.views < 5000) {
      return "1k+";
    } else {
      return "5k+";
    }
  }, [opportunity.views]);
  return (
    <>
      <style jsx>{`
          .marquee {
            height: 24px;
            overflow: hidden;
            position: relative;
          }
          .marquee p {
            margin: 0;
            position: absolute;
            width: 100%;
            display: none;
          }
          .scroll {
            animation: scroll 12s linear;
          }
          @keyframes scroll {
            0%, 25% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-24px);
            }
            100% {
              transform: translateY(-24px);
            }
          }
        `}</style>
      <div
        className="marquee h-[24px] w-full overflow-hidden"
        id={`${activeType === "draft" ? `marquee-${opportunity.id}` : `marquee-${opportunity.opportunity_id}`}`}
      >
        {isBusiness ? (
          <>
          {activeType === "draft" ? (
            <>
             <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>{"0 views"}</p>
              <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>{`${opportunity.quantity} ${(opportunity.type === "event" ? "ticket" : "deal")}${opportunity.quantity === 1 ? "" : "s"} left`}</p>
            </>
          ) : (
            <>
              <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>{opportunity.views === 1 ? "1 view" : opportunity.views + " views"}</p>
              <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>{`${opportunity.quantity} ${(opportunity.type === "event" ? "ticket" : "deal")}${opportunity.quantity === 1 ? "" : "s"} left`}</p>
            </>
          )}
        </>
        ) : (
          <>
            <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>
              {phase}
            </p>
            <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>
              {opportunity.cashback}% cash back
            </p>
            <p className="text-carousel text-grey font-light " style={{ color: "grey", height: "24px" }}>
              {opportunity.views < 10 ? views : views + " views"}
            </p>
            <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>
              Local {opportunity.category}
            </p>
            <p className="text-carousel text-grey font-light" style={{ color: "grey", height: "24px" }}>
              Too good to last
            </p>
          </>
        )}
      </div>
    </>
  );
}