import React, { useRef ,useContext } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  AccountContext,
  AccountContextType,
} from "src/components/contexts/ToggleAccountContext";
import SwiperContainer from "./SwiperContainer";
import { default as ImageWrapper } from "./Image";
import NoImageLogo from "./NoImageLogo";
import NoImageDate from "./NoImageDate";
// Shows a carousel of images for an opportunity with swipe functionality on mobile. Will only display 1 image at a time.
// Use ImageGallery to display multiple images at a time.
interface ImageCarouselProps {
  opportunity: {
    title: string;
    business_image: string;
    media: string[];
    expiration_date: Date;
    value: number;
    difference: number;
  };
  priority?: boolean;
  square?: boolean;
}
const ImageCarousel: React.FC<ImageCarouselProps> = ({ opportunity, priority, square}) => {
  const images = opportunity?.media || [];
  const ref = useRef<HTMLDivElement>(null);

  const { isBusiness } =useContext<AccountContextType>(AccountContext);
  // Parse expiration date as UTC
  const expirationDate = new Date(opportunity.expiration_date);
  expirationDate.setDate(expirationDate.getDate() + 1);
  const now = new Date();
  const sixtyDaysAgo = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000); // Calculate the date 60 days ago
  const expiredSixtyDaysAgo = expirationDate <= now && expirationDate >= sixtyDaysAgo;
  const sold = opportunity.difference <= 0;

  return (
    <div className="w-full cursor-pointer" ref={ref}>
      <SwiperContainer>
        <Swiper
          preventClicks={true}
          className={`${square ? "aspect-[1/1]" : "aspect-[3/2]"}`}
          spaceBetween={20}
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={!!opportunity}
          loop
          // There is an issue in iPhone that clicking doesn't propagate the event if the arrow are not shown
          // Here we click the parent component which will always propagate accordingly
          onClick={() => ref.current?.click()}
        >
          {images.length ? (
            <>
              <SwiperSlide>

                <ImageWrapper src={images[0] || ""} priority={priority} expiredSixtyDaysAgo={expiredSixtyDaysAgo} sold={sold } isBusiness={isBusiness} />
              </SwiperSlide>
              <SwiperSlide>
                <ImageWrapper src={images[1] || ""}  expiredSixtyDaysAgo={expiredSixtyDaysAgo}  sold={sold} isBusiness={isBusiness}/>
              </SwiperSlide>
            </>
          ) : (
            <>
              <SwiperSlide>
                <NoImageLogo
                  src={opportunity.business_image}
                  value={opportunity.value}
                  priority={priority}
                  sold={sold}
                  expiredSixtyDaysAgo={expiredSixtyDaysAgo}
                  isBusiness={isBusiness}
                />
              </SwiperSlide>
              <SwiperSlide>
                <NoImageDate date={opportunity.expiration_date} sold={sold}  expiredSixtyDaysAgo={expiredSixtyDaysAgo} isBusiness={isBusiness}/>
              </SwiperSlide>
            </>
          )}
          <div className="swiper-button-next" onClick={(e) => e.stopPropagation()} />
          <div className="swiper-button-prev" onClick={(e) => e.stopPropagation()} />
        </Swiper>
      </SwiperContainer>
    </div>
  );
};
export default ImageCarousel;