import { Skeleton } from "@mui/material";
import NextImage from "next/image";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { useRouter } from "next/router";
interface ImageProps {
  src: string;
  priority?: boolean;
  sold: boolean;
  expiredSixtyDaysAgo: boolean;
  isBusiness:boolean; // Change type to string
}
const Image: React.FC<ImageProps> = ({ src, priority, sold, expiredSixtyDaysAgo,isBusiness }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const router = useRouter();
  const detailPage = router.pathname.includes("/detail")
  return (
    <div className="relative h-full w-full">
      {(expiredSixtyDaysAgo || sold) && !isBusiness && !detailPage ? (
        <>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="100%"
            width="100%"
            className={twMerge(
              "absolute aspect-[1/1] object-cover",
              window.location.pathname === "/" ? "rounded-xl sm:rounded-l-xl" : "md:rounded-[30px]",
              isImageLoaded ? "opacity-0" : "opacity-100"
            )}
          />
          <NextImage
            src={src}
            alt="Opportunity image"
            className={twMerge(
              "aspect-[1/1]",
              window.location.pathname === "/" ? "rounded-xl sm:rounded-l-xl" : "md:rounded-[30px]",
              isImageLoaded ? "opacity-50" : "opacity-0"
            )}
            onLoadingComplete={() => setIsImageLoaded(true)}
            fill
            priority={!!priority}
            style={{ objectFit: "cover" }}
          />
          <div
            className={`absolute ${isImageLoaded ? "opacity-100" : "opacity-0"} bottom-[25px] right-[12px] p-2 h-[45px] w-[100px] flex justify-center items-center bg-black`}
            style={{ borderRadius: "6px" }}
          >
            <p className="text-white">{sold ? "Sold Out" : "Expired"}</p>
          </div>
        </>
      ) : (
        <>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="100%"
            width="100%"
            className={twMerge(
              "absolute aspect-[1/1] object-cover",
              window.location.pathname === "/" ? "rounded-xl sm:rounded-l-xl" : "md:rounded-[30px]",
              isImageLoaded ? "opacity-0" : "opacity-100"
            )}
          />
          <NextImage
            src={src}
            alt="Opportunity image"
            className={twMerge(
              "aspect-[1/1]",
              window.location.pathname === "/" ? "rounded-xl sm:rounded-l-xl" : "md:rounded-[30px]",
              isImageLoaded ? "opacity-100" : "opacity-0"
            )}
            onLoadingComplete={() => setIsImageLoaded(true)}
            fill
            priority={!!priority}
            style={{ objectFit: "cover" }}
          />
        </>
      )}
    </div>
  );
};
export default Image;