import styled from "styled-components";

const SwiperContainer = styled.div`
  :root {
    --swiper-navigation-size: 15px;
  }
  .swiper-pagination-bullet {
    background-color: black;
  }
  .swiper-pagination-bullet-active {
    background-color: white;
  }
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0;
    --swiper-navigation-size: 17px;
    position: absolute;
    top: 50%;
    margin-top: 0.2rem;
    transform: translateY(-50%);
    width: 35px;
    font-size: 5px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }
  .swiper:hover .swiper-button-next,
  .swiper:hover .swiper-button-prev {
    opacity: 1;
    --swiper-navigation-size: 20px;
    background-color: white;
    border: 3px white solid;
    color: gray;
    transform: translateY(-50%) scale(1.1);
  }
  .swiper-button-next {
    right: 10px;
  }
  .swiper-button-prev {
    left: 10px;
  }

  /* Media query for mobile devices */
  @media only screen and (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
    }
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
      opacity: 0;
    }
  }
`;

export default SwiperContainer;
