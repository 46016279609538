import moment from "moment";
import React from "react";
import { twMerge } from "tailwind-merge";
interface NoImageDateProps {
  date?: Date | undefined;
  soldOut: number;
  expiredSixtyDaysAgo: boolean;
  sold: boolean;
  isBusiness:boolean;
}
const NoImageDate: React.FC<NoImageDateProps> = ({ date, sold, expiredSixtyDaysAgo,isBusiness }) => {
  const formattedDate = date ? moment(date).utc().format("MM/DD/YY") : "";
  const text = date ? "Valid until" : "Valid indefinitely";
  return (
    <>
      <div
        className={twMerge(
          window.location.pathname === "/"
            ? "rounded-xl sm:rounded-l-xl"
            : "md:rounded-[30px]",
          "relative flex h-full w-full flex-col items-center justify-center bg-[#EAAC00]",
          (expiredSixtyDaysAgo || sold) && !isBusiness ? "opacity-50" : "opacity-90"
        )}
        style={{
          borderRadius: window.location.pathname === "/" ? "rounded-xl sm:rounded-l-xl" : "md:rounded-[30px]"
        }}
      >
        <p className="font-['Arial'] text-4xl font-bold text-white sm:text-2xl">
          {text}
        </p>
        {date && (
          <p className="font-['Arial'] text-4xl font-bold text-white sm:text-2xl">
            {formattedDate}
          </p>
        )}
      </div>
      <div>
        {(expiredSixtyDaysAgo || sold) && !isBusiness ? (
          <div
            className="absolute bottom-[25px] right-[12px] p-2 h-[45px] w-[100px] flex justify-center items-center bg-black"
            style={{ borderRadius: "6px" }}
          >
            <p className="text-white">{sold ? "Sold Out" : "Expired"}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default NoImageDate;